import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  getAuth,
  RecaptchaVerifier,
  signInWithCustomToken,
} from 'firebase/auth';
import LogInForm from './components/LogInForm';
import Loading from '../shared/components/custom/Loading';
import OneTimeCodeForm from './components/OneTimeCodeForm';
import EnterUsernameForm from './components/EnterUsernameForm';
import ForgotPasswordEnterNewPass from './components/ForgotPasswordEnterNewPass';
import t, { partial } from '../util/translation/translation';
import Api from '../util/api';
import NOLogo from '../../assets/neveroffside-logo.svg';

const RenderTabs = ({ tab, setTab, buttonLoading, history, sendCode, sendRestorePasswordCode, updatePassword, forgotPassUsername, user, verifyCode }) => {
  switch (tab) {
    case 'username':
      return (
        <LogInForm
          loading={buttonLoading}
          goToEmail={() => history.push('/email_login')}
          onSubmit={sendCode}
          goToForgot={() => {
            setTab('forgotPassword');
          }}
        />
      );
    case 'forgotPassword':
      return <EnterUsernameForm onSubmit={sendRestorePasswordCode} />;
    case 'forgotPasswordEnterNewPass':
      return (
        <ForgotPasswordEnterNewPass
          onSubmit={updatePassword}
          username={forgotPassUsername}
        />
      );
    case 'password':
      return (
        <OneTimeCodeForm
          username={user?.username}
          onSubmit={verifyCode}
        />
      );
    default:
      return null;
  }
};
const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [tab, setTab] = useState('username');
  const [user, setUser] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [forgotPassUsername, setForgotPassUsername] = useState('');
  const history = useHistory();
  const p = partial('LoginPage');
  function handleError(error) {
    switch (error) {
      case 'auth/invalid-phone-number': return toast.error(p('userNotFound'));
      case 'auth/invalid-verification-code': return toast.error(p('pleaseEnterCorrectCode'));
      case 'auth/too-many-requests': {
        toast.error(p('tooManyRequests'));
        return toast.success(p('proceedToEmailLogin'));
      }
      case 'INVALID_CODE': return toast.error(p('pleaseEnterCorrectCode'));
      case 'auth/code-expired': {
        setTab('username');
        return toast.error(p('codeExpired'));
      }
      default: return toast.error(p('errorSendingSMS'));
    }
  }
  async function sendCode({ username }) {
    try {
      const { phone, code } = username;
      const onlyPhone = phone?.replace(`+${code}`, '');
      let completePhone = onlyPhone;
      if (onlyPhone?.startsWith('0')) {
        completePhone = onlyPhone.slice(1, onlyPhone.length);
      }
      const phoneWithCode = `+${code}${completePhone}`;
      setButtonLoading(true);
      try {
        await Api.login.doesUserExist({ phone: phoneWithCode });
      } catch {
        throw Object.assign(new Error(), { code: 'auth/invalid-phone-number' });
      }
      const auth = getAuth();
      const applicationVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        { size: 'invisible' },
        auth,
      );
      const recaptchaToken = await applicationVerifier.verify();
      const { sessionInfo } = await Api.authorization.sendSignInWithPhone({ phoneNumber: phoneWithCode, recaptchaToken });
      setConfirmation(sessionInfo);
      setButtonLoading(false);
      const payload = {
        country_code: '+47',
        username: phoneWithCode,
      };
      setUser(payload);
      setTab('password');
      setLoading(false);
      toast.success(`${p('oneTimeCodeSentTo')} ${phoneWithCode}`);
    } catch (err) {
      handleError(err.code);
      setLoading(false);
      setButtonLoading(false);
    }
  }
  const verifyCode = async ({ code }) => {
    if (!user) {
      toast.error(p('userNotFound"'));
      setTab('username');
      return;
    }
    setLoading(true);
    try {
      const { idToken } = await Api.authorization.verifyPhoneCode({
        sessionInfo: confirmation,
        phoneNumber: user.username,
        code,
      });
      const { token: customToken } = await Api.authorization.getCustomToken(idToken);
      const auth = getAuth();
      await signInWithCustomToken(auth, customToken);
      setLoading(false);
      toast.success(t('LoginPage.successfullLogin'));
    } catch (err) {
      handleError(err?.message ? { code: err?.message } : err);
      setLoading(false);
    }
  };

  const sendRestorePasswordCode = async ({ username }) => {
    if (!username) {
      toast.error(p('userNotFound'));
      return;
    }
    setForgotPassUsername(username);
  };
  const updatePassword = async (values) => {
    const { passwordConfirm, password } = values;
    if (passwordConfirm !== password) {
      toast.error(p('pleaseEnterCorrectPassword'));
    }
  };


  return (
    <div className="account">
      <Loading loading={loading} />
      <div className="account__wrapper">
        <div className="account__card">
          <img src={NOLogo} alt="Företagslogo" className="my-30" />
          <hr />
          <h3 className="bold-text">{p('welcomeToThePartnerPortal')}</h3>
          <RenderTabs
            tab={tab}
            setTab={setTab}
            buttonLoading={buttonLoading}
            history={history}
            sendCode={sendCode}
            sendRestorePasswordCode={sendRestorePasswordCode}
            updatePassword={updatePassword}
            forgotPassUsername={forgotPassUsername}
            user={user}
            verifyCode={verifyCode}
          />
          <div id="recaptcha-container" />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
